import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TacticalImg from "../components/playerImage"

const contact = () => {
  return (
    <Layout>
      <SEO
        title="Trading Players"
        description="Learn how you can trade players in PES 2021 myClub. Exchange your replicas for the players you really want."
      />
      <div className="container">
        <h1>Trading Players in PES 2021</h1>
        <ul className="breadcrumb" style={{ paddingTop: "1rem" }}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>Trading Players</li>
        </ul>
        <p>
          You can trade 3 copies of the exact same player for a different player
          of the same rarity (level 1).
        </p>
        <p>
          This means that if you have 3 Messis, you can swap for any other Black
          Ball, like Ronaldo. It's important to note that you cannot trade
          players for <Link to="/legends/">legends</Link>.
        </p>
        <p>
          You can't scout players you already have in your club, so the only way
          you get 3 copies of the same player is by spinning Agents.
        </p>
        <p>
          There are some additional things to keep in mind, and this is what
          creates some confusion among PES myClub players.
        </p>
        <p>
          You need to have the exact version of the player 3 times for the trade
          to work - you can’t mix featured player versions with regular
          versions, so you have to be lucky (or unlucky) enough to get the same
          featured player 3 times on that week (if they are from different
          weeks, they won't count either).
        </p>
        <p>
          Assuming your 3 players fulfil these requirements, just go to the
          CONTRACT / TRADE screen and pick the player you want to trade.
        </p>
        <p>
          In the image below, you can see that I was lucky enough to have 3
          Ronaldos in the club, so I can select all 3 to trade that player for a
          different Black Ball.
        </p>
        <TacticalImg
          src="pes2021-player-trade-selection.jpg"
          alt="Selecting Players to trade in PES 2021"
        />
        <p>
          After you made the selection, press the "Confirm Trade" button and
          choose the player you wish to trade for. You will then get this
          confirmation screen to execute the trade.
        </p>
        <TacticalImg
          src="pes2021-player-trade-execution.jpg"
          alt="Confirming the player to trade in PES 2021"
        />
        <p>
          In the case of Featured Players, you won't be able to trade them
          unless you have 3 exact copies of that player. If you have just 1
          replica (and you actually enjoy using that player), I would suggest
          you have a look at the{" "}
          <Link to="/training-players/">Training Players</Link> section instead.
        </p>
        <p>
          You will never get the chance to have another replicae once you spend
          all 3 attempts of that week, so training that player is probably your
          best action.
        </p>
      </div>
    </Layout>
  )
}

export default contact
